import * as React from 'react';
import propTypes from 'prop-types';

import { SocialButton } from './styled';

function MicrosoftLoginButton({ onClick, className, title, disabled }) {
  return (
    <button type="button" onClick={onClick} className={className} disabled={disabled}>
      <span>
        <svg height="18" width="18" viewBox="0 0 21 21" style={{ marginRight: 16 }}>
          <rect x="1" y="1" width="9" height="9" fill="#f25022" />
          <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
          <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
          <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
        </svg>
        {title}
      </span>
    </button>
  );
}

MicrosoftLoginButton.propTypes = {
  onClick: propTypes.func,
  className: propTypes.string,
  title: propTypes.string,
  disabled: propTypes.bool,
};

MicrosoftLoginButton.defaultProps = {
  onClick: () => {},
  className: '',
  title: '',
  disabled: false,
};

export const StyledMicrosoftLoginButton = SocialButton(MicrosoftLoginButton);
