import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/ui/button';
import { Link } from 'gatsby';
import { GoogleBtn, OrDivider } from './styles';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  & h4 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 50px;
    line-height: 28px;
  }
  & p {
    margin-bottom: 50px;
  }
  ${GoogleBtn}:hover {
    text-decoration: none;
  }
`;

const DomainExists = ({ forceCreateAccount, errorMsg, disabled, providerErr, googleAccount }) => {
  const renderProviderBtn = (provider) => {
    switch (provider) {
      case 'google':
        return googleAccount;
      default:
        return null;
    }
  };
  return (
    <Container>
      <h4>Choose your path</h4>
      <p>
        An account using <strong>{errorMsg.split(' ')[3]}</strong> domain already exists. If you
        want to join this account ask your organization owner to send you an invitation.
      </p>
      <GoogleBtn className="signup" as={Link} to="/">
        Go back
      </GoogleBtn>
      <OrDivider style={{ width: '100%' }}>
        <span>or</span>
      </OrDivider>
      {errorMsg && providerErr ? (
        renderProviderBtn(providerErr)
      ) : (
        <Button
          className="btn-create-account"
          disabled={disabled}
          as="button"
          style={{
            textTransform: 'uppercase',
            width: '100%',
          }}
          onClick={forceCreateAccount}
          withLoading
        >
          Create new account
        </Button>
      )}
    </Container>
  );
};

export default DomainExists;

DomainExists.propTypes = {
  forceCreateAccount: PropTypes.func.isRequired,
  errorMsg: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  googleAccount: PropTypes.element.isRequired,
  providerErr: PropTypes.string.isRequired,
};
