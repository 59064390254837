import React, { useState } from 'react';
import propTypes from 'prop-types';
import { PublicClientApplication } from '@azure/msal-browser';

export function MicrosoftLoginButtonHOC(Button) {
  function MicrosoftLoginButton({
    clientId,
    redirectUri,
    authCallback,
    onFailure,
    title,
    className,
    disabled,
    children,
  }) {
    const [instance] = useState(
      new PublicClientApplication({
        auth: {
          clientId,
          redirectUri,
        },
      }),
    );

    function onClick() {
      instance.loginPopup().then(authCallback).catch(onFailure);
    }

    return (
      <Button
        onClick={onClick}
        className={className}
        title={title}
        disabled={disabled || !instance}
      >
        {children}
      </Button>
    );
  }

  MicrosoftLoginButton.propTypes = {
    clientId: propTypes.string.isRequired,
    redirectUri: propTypes.string.isRequired,
    authCallback: propTypes.func.isRequired,
    onFailure: propTypes.func.isRequired,
    title: propTypes.string,
    className: propTypes.string,
    disabled: propTypes.bool,
    children: propTypes.oneOfType([propTypes.arrayOf(propTypes.node), propTypes.node]),
  };

  MicrosoftLoginButton.defaultProps = {
    title: '',
    className: '',
    disabled: false,
    children: null,
  };

  return MicrosoftLoginButton;
}
