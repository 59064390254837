import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Layout from 'layout';
import RegisterForm from 'components/signup/register-form';
import logoDark from 'img/LogoLS/logo_livesession_dark.svg';
import 'css/entypo.css';
import { media } from 'theme/breakpoints';
import { getTrackingValues } from 'services/tracking';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const FormContainer = styled.div`
  max-width: 434px;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  position: relative;
  z-index: 10;
  justify-content: center;
`;

const SignupSection = styled.section`
  width: 100%;
  min-height: 100vh;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', 'Muli', sans-serif;
`;

const Logo = styled(Link)`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 48px;
  top: 48px;
  line-height: normal;
  ${media.desktop} {
    display: none;
  }
  & img {
    max-width: 125px;
    height: auto;
  }
`;

const PromoCode = styled.h3`
  font-weight: 500;
  font-size: 16px;
  max-width: 420px;
  line-height: 29px;
  border: 2px solid #4caf50;
  margin: 0 auto 42px auto;
  border-radius: 4px;
  padding: 16px;
  background-color: honeydew;
`;

const UsingLS = styled.div`
  position: absolute;
  top: 48px;
  right: 48px;
  font-family: 'Roboto', 'Muli', sans-serif;
  ${media.desktop} {
    display: none;
  }
  & p {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin: 0;
    ${media.desktop} {
      font-size: 14px;
      line-height: normal;
    }
  }
`;

const SignUp = () => {
  const [isPromoActive, setData] = useState('');
  useEffect(() => {
    const data = getTrackingValues();
    if (!isPromoActive && data) {
      setData(data);
    }
  }, [isPromoActive]);
  return (
    <Layout
      hideFooter
      hideHeader
      metaTitle="Signup to LiveSession"
      canonical="/signup"
      blockScrollX
      noIndex
    >
      <Logo to="/">
        <img src={logoDark} alt="logo" title="LiveSession - logo" />
      </Logo>
      <UsingLS>
        <p>
          Already have an account? <a href={`${process.env.GATSBY_APP_URL}`}>Log in</a>
        </p>
      </UsingLS>
      <SignupSection>
        <div>
          <FormContainer>
            <div className="text-center">
              {isPromoActive.promo_code && isPromoActive.promo_code === 'CROWEB01' && (
                <PromoCode>
                  <strong>Special offer for webinar attendees:</strong>
                  <br />
                  register now and get 20% off the annual plan!
                </PromoCode>
              )}

              {/* <FreeTrial>
              <p>
                All plans come with 14 days free, no credit card required
              </p>
            </FreeTrial> */}
            </div>
            {/* <form method="GET" action={`http://localhost:7090/accounts/oauth/google`}>
                <div className="form-row">
                <GoogleBtn type="submit" className="outline gray">
                  <img alt="logo" src={googleLogo} style={{ maxWidth: 20, marginRight: ".5rem" }} />Sign up with Google</GoogleBtn>
                </div>
                <OrDivider>or</OrDivider>
              </form> */}
            <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_ID}>
              <RegisterForm />
            </GoogleReCaptchaProvider>
          </FormContainer>
          {/* <BenefitsContainer className="col-12 col-lg-7 card-body">
          <img src={signupImg} alt="LivSession application" />
        </BenefitsContainer> */}
        </div>
      </SignupSection>
    </Layout>
  );
};

export default SignUp;
