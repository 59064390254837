import styled from 'styled-components';

export function SocialButton(Button) {
  return styled(Button)`
    &.signup {
      background: #ffffff;
      border: 1px solid #000000;
      border-radius: 4px;
      max-width: 100%;
      height: 48px;
      text-transform: initial;
      align-items: center;
      display: block;
      justify-content: center;
      line-height: normal;
      color: #000000;
      transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      padding: 13px 0;
      width: 100%;
      outline: 0;
      &.disabled {
        opacity: 0.5;
        &:hover {
          border-color: #bfbfbf;
          color: #ffffff;
        }
      }
      &:hover {
        border-color: #000;
        color: #fff;
        background-color: #000;
      }
    }
  `;
}
