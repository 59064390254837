import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const OrDivider = styled.div`
  position: relative;
  display: block;
  margin: 32px 0;
  font-size: 15px;
  color: #aaa;
  background: #fff;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 1px solid #e4e5e8;
  }

  span {
    position: relative;
    z-index: 1;
    background: #fff;
    padding: 0 30px;
  }
`;

export const GoogleBtn = styled.button`
  &.signup {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 4px;
    max-width: 100%;
    height: 48px;
    text-transform: initial;
    align-items: center;
    display: block;
    justify-content: center;
    line-height: normal;
    color: #000000;
    transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 13px 0;
    width: 100%;
    outline: 0;
    &.disabled {
      opacity: 0.5;
      &:hover {
        border-color: #bfbfbf;
        color: #ffffff;
      }
    }
    &:hover {
      border-color: #000;
      color: #fff;
      background-color: #000;
    }
  }
`;

export const ErrorAlert = styled.p`
  text-align: center;
  font-size: 0.75rem;
  margin: 0 auto 0.75rem;
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: #f44336;
  font-weight: 500;
  width: 100%;
  @media (max-width: 1200px) {
    margin: 0 auto 0.75rem;
  }
`;

export const ThankYou = styled.div`
  p {
    text-align: center;
    font-size: 0.75rem;
    margin: 0 0 0.75rem 0;
    color: white;
    padding: 0.5rem;
    border-radius: 4px;
    background-color: #4caf50;
    font-weight: 500;
  }
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
    li {
      justify-content: center;
      text-align: center;
      a {
        font-size: 0.65rem;
        padding: 0.5rem 0.25rem;
      }
    }
  }
`;

export const SignUpInfo = styled.p`
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin: 32px 0 0;
`;

export const Header = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 40px;
  text-align: center;
  ${media.mobile} {
    margin-bottom: 32px;
  }
`;
