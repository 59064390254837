import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'gatsby';
import { getTrackingValues } from 'services/tracking';
import queryString from 'query-string';
import googleLogo from 'img/ui/ga.svg';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Button from 'components/ui/button';
import { GoogleLogin } from 'react-google-login';
import ls from '@livesession/sdk';
import Input from './signupInput';
import { MicrosoftLoginButton } from '../../containers/MicrosoftLogin/MicrosoftLoginButton';
import DomainExists from './domainExist';
import { ErrorAlert, ThankYou, SignUpInfo, Header, OrDivider, GoogleBtn } from './styles';

const STATE_NEW = 'NEW';
const STATE_DONE = 'DONE';
const STATE_INPROGRESS = 'INPROGRESS';
const STATE_ERROR = 'ERROR';

const enableMicrosoft = false;

class RegisterForm extends React.Component {
  state = {
    formState: STATE_NEW,
    errorMsg: '',
    email: '',
    password: '',
    errorCode: '',
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (typeof window !== 'undefined' && window && window.location.search) {
      const parsed = queryString.parse(window.location.search);
      if (parsed.email) {
        this.setState({
          email: parsed.email,
        });
      }
    }
  }

  async getReCaptchaToken() {
    const { googleReCaptchaProps } = this.props;
    const token = await googleReCaptchaProps.executeRecaptcha('signup');
    return token;
  }

  validateInputs = () => {
    const { password, email } = this.state;

    if (typeof password === 'string' && typeof email === 'string') {
      if (password.length < 6) {
        this.setState({
          formState: STATE_ERROR,
          errorMsg: 'Password should have at least 6 characters.',
        });
        return false;
      }

      if (email.length <= 0) {
        this.setState({
          formState: STATE_ERROR,
          errorMsg: 'Please provide us with your email.',
        });
        return false;
      }

      return true;
    }

    this.setState({
      formState: STATE_ERROR,
      errorMsg: 'Please type email and password.',
    });
    return false;
  };

  handleChange = (event) => {
    switch (event.target.type) {
      case 'email':
        this.setState({ email: event.target.value.toLowerCase() });
        break;
      case 'password':
        this.setState({ password: event.target.value });
        break;
      default:
        break;
    }
  };

  handleSubmit = (event, forceAccount = false) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({ formState: STATE_INPROGRESS, errorMsg: '', errorCode: '', providerErr: '' });

    if (this.validateInputs()) {
      const { email, password } = this.state;
      this.getReCaptchaToken().then((token) => {
        const requestBody = {
          login: email,
          password,
          token,
          force: forceAccount,
          ...getTrackingValues(),
        };

        axios
          .post(`${process.env.GATSBY_API_URL}/accounts`, requestBody)
          .then((res) => {
            // eslint-disable-next-line no-shadow
            const { token = false } = res.data;
            this.sendEventToTrackers(email);
            if (token) {
              if (window.ga) {
                window.ga('send', 'event', {
                  eventCategory: 'Signup',
                  eventAction: 'Create trial account',
                  eventLabel: 'With email',
                });
              }
              ls.track('Signup');
              document.location.assign(`${process.env.GATSBY_APP_URL}/token?token=${token}`);
            } else if (res?.data?.error?.type === 'rate-limiter') {
              this.setState({
                formState: STATE_ERROR,
                errorMsg: 'Too many requests. Try again later.',
              });
            } else {
              ls.track('Signup');
              this.setState({ formState: STATE_DONE });
            }
          })
          .catch((err) => {
            this.setState({
              formState: STATE_ERROR,
              errorMsg: this.parseError(err),
              errorCode: err?.response?.data?.error?.code,
            });
          });
      });
    }
  };

  handleGoogleError = () => {
    this.setState({
      formState: STATE_ERROR,
      errorMsg: "Can't sign in with Google.",
    });
  };

  handleMicrosoftError = () => {
    this.setState({
      formState: STATE_ERROR,
      errorMsg: "Can't sign up with Microsoft.",
    });
  };

  handleGoogleResponse = (response, forceAccount = false) => {
    this.setState({ formState: STATE_INPROGRESS, errorMsg: '', errorCode: '', providerErr: '' });

    return this.createThirdPartyAccount(
      'google',
      response.profileObj.email,
      response.accessToken,
      forceAccount,
    );
  };

  handleMicrosoftResponse = (response) => {
    this.setState({ formState: STATE_INPROGRESS, errorMsg: '', errorCode: '', providerErr: '' });

    return this.createThirdPartyAccount(
      'microsoft',
      response.account.username,
      response.accessToken,
    );
  };

  parseError = (err) => {
    const { response } = err;
    if (response?.data?.error?.type) {
      switch (response?.data?.error?.type) {
        case 'account_exists':
          return 'Account with this email already exists.';
        case 'validation':
          if (response?.data?.error?.message) {
            return response?.data?.error?.message;
          }
          return 'This is not a correct email. Please check it and try again.';
        case 'data_format':
          return 'Please check your email and password, then try again.';
        default:
          return 'Something went wrong. Please check your email and password, then try again.';
      }
    } else {
      return 'Something went wrong. Please check your email and password, then try again.';
    }
  };

  createThirdPartyAccount(provider, email, accessToken, forceAccount = false) {
    return this.getReCaptchaToken().then((token) => {
      const requestBody = {
        provider,
        login: email,
        password: accessToken,
        force: forceAccount,
        token,
        ...getTrackingValues(),
      };

      axios
        .post(`${process.env.GATSBY_API_URL}/accounts`, requestBody, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => {
          // eslint-disable-next-line no-shadow
          const { token = false } = res.data;
          // this.sendEventToTrackers(email);
          if (token) {
            if (window.ga) {
              window.ga('send', 'event', {
                eventCategory: 'Signup',
                eventAction: 'Create trial account',
                eventLabel: `With ${provider}`,
              });
            }
            ls.track('Signup', {
              provider_str: provider,
            });
            document.location.assign(`${process.env.GATSBY_APP_URL}/activate?token=${token}`);
          } else {
            ls.track('Signup', {
              provider_str: provider,
            });
            this.setState({ formState: STATE_DONE });
          }
        })
        .catch((err) => {
          this.setState({
            formState: STATE_ERROR,
            errorMsg: this.parseError(err),
            errorCode: err?.response?.data?.error?.code,
            providerErr: provider,
          });
        });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  sendEventToTrackers(email) {
    if (window.ga) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Signup',
        eventAction: 'Account Signup',
        eventLabel: 'Signup v.1',
      });
    }

    if (window.fbq) {
      window.fbq('track', 'Lead');
    }

    if (window.Autopilot) {
      window.Autopilot.run('associate', email);
    }

    // eslint-disable-next-line no-underscore-dangle
    if (window._hsq) {
      // eslint-disable-next-line no-underscore-dangle
      window._hsq.push([
        'identify',
        {
          email,
        },
      ]);
    }
  }

  renderForm = () => {
    const { formState, email, password } = this.state;
    const disabled = formState === STATE_INPROGRESS;
    const CTALabel = 'Sign up';

    return (
      <>
        <GoogleLogin
          icon={null}
          clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}
          onSuccess={this.handleGoogleResponse}
          onFailure={this.handleGoogleError}
          cookiePolicy="single_host_origin"
          disabled={disabled}
          render={(renderProps) => (
            <GoogleBtn
              onClick={renderProps.onClick}
              className={['signup', renderProps.disabled && 'disabled'].join(' ')}
              disabled={renderProps.disabled}
            >
              <img alt="logo" src={googleLogo} style={{ maxWidth: 20, marginRight: '16px' }} />
              {CTALabel} with Google
            </GoogleBtn>
          )}
        />
        {enableMicrosoft && (
          <div style={{ marginTop: 24 }}>
            <MicrosoftLoginButton
              clientId={process.env.GATSBY_MICROSOFT_CLIENT_ID}
              redirectUri={process.env.GATSBY_MICROSOFT_CALLBACK_URL}
              authCallback={this.handleMicrosoftResponse}
              onFailure={this.handleMicrosoftError}
              disabled={disabled}
              className={['signup', disabled && 'disabled'].join(' ')}
            />
          </div>
        )}
        <OrDivider>
          <span>or sign up with email</span>
        </OrDivider>
        <form onSubmit={this.handleSubmit}>
          <div className="form-row justify-content-center">
            <Input
              className="input-email"
              value={email}
              type="email"
              id="email"
              textLabel="Email"
              onChange={this.handleChange}
              disabled={disabled}
              required
              autoFocus
              style={{ marginBottom: 24 }}
            />
          </div>
          <div className="form-row justify-content-center">
            <Input
              className="input-email"
              value={password}
              type="password"
              id="password"
              textLabel="Password"
              onChange={this.handleChange}
              disabled={disabled}
              required
            />
          </div>
          <div className="form-row justify-content-center">
            <Button
              className="btn-create-account"
              type="submit"
              disabled={disabled}
              as="button"
              style={{
                textTransform: 'uppercase',
                width: '100%',
                marginTop: 32,
              }}
              withLoading
            >
              {CTALabel} free
            </Button>
          </div>
          <SignUpInfo>
            By clicking &quot;{CTALabel} free&quot; you agree to our{' '}
            <Link to="/terms-of-service">terms of service</Link> and{' '}
            <Link to="/privacy-policy">privacy policy</Link>. This site is protected by reCAPTCHA
            and the Google{' '}
            <a
              rel="noopener noreferer nofollow"
              target="_self"
              href="https://policies.google.com/privacy"
            >
              privacy policy
            </a>{' '}
            and{' '}
            <a
              rel="noopener noreferer nofollow"
              target="_self"
              href="https://policies.google.com/terms"
            >
              terms of service
            </a>{' '}
            apply.
          </SignUpInfo>
        </form>
      </>
    );
  };

  renderThankYou = () => {
    return (
      <ThankYou>
        <p>
          We&apos;ve sent you an email with activation link. Please check your mailbox and activate
          your account.
        </p>
        <ul>
          <li>
            <a rel="noopener noreferer nofollow" target="_self" href="https://gmail.com">
              Gmail
            </a>
          </li>
          <li>
            <a rel="noopener noreferer nofollow" target="_self" href="https://outlook.com">
              Outlook
            </a>
          </li>
          <li>
            <a rel="noopener noreferer nofollow" target="_self" href="https://mail.yahoo.com">
              Yahoo Mail
            </a>
          </li>
          <li>
            <a rel="noopener noreferer nofollow" target="_self" href="https://mail.aol.com">
              Aol
            </a>
          </li>
          <li>
            <a rel="noopener noreferer nofollow" target="_self" href="https://www.icloud.com/mail">
              iCloud Mail
            </a>
          </li>
        </ul>
      </ThankYou>
    );
  };

  renderError = () => {
    const { errorMsg, errorCode, formState, providerErr } = this.state;
    const errMsg = errorMsg === '' ? 'Something went wrong!' : errorMsg;

    return (
      <>
        {errorCode === 'domain_exists' ? (
          <DomainExists
            errorMsg={errorMsg}
            forceCreateAccount={() => this.handleSubmit(null, true)}
            disabled={formState === STATE_INPROGRESS}
            providerErr={providerErr}
            googleAccount={
              <GoogleLogin
                icon={null}
                clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}
                onSuccess={(res) => this.handleGoogleResponse(res, true)}
                onFailure={this.handleGoogleError}
                cookiePolicy="single_host_origin"
                disabled={formState === STATE_INPROGRESS}
                render={(renderProps) => (
                  <GoogleBtn
                    onClick={renderProps.onClick}
                    className={['signup', renderProps.disabled && 'disabled'].join(' ')}
                    disabled={renderProps.disabled}
                  >
                    <img
                      alt="logo"
                      src={googleLogo}
                      style={{ maxWidth: 20, marginRight: '16px' }}
                    />
                    Sign up with Google
                  </GoogleBtn>
                )}
              />
            }
          />
        ) : (
          <>
            <ErrorAlert>{errMsg}</ErrorAlert>
            {this.renderForm()}
          </>
        )}
      </>
    );
  };

  render() {
    const { formState } = this.state;

    let result;
    switch (formState) {
      case STATE_DONE:
        result = this.renderThankYou();
        break;
      case STATE_ERROR:
        result = this.renderError();
        break;
      case STATE_NEW:
        result = this.renderForm();
        break;
      case STATE_INPROGRESS:
        result = this.renderForm();
        break;
      default:
        result = this.renderForm();
        break;
    }
    const { errorCode } = this.state;
    return (
      <>
        <Header style={{ display: errorCode === 'domain_exists' ? 'none' : 'block' }}>
          Create your account
        </Header>
        {result}
      </>
    );
  }
}

export default withGoogleReCaptcha(RegisterForm);

RegisterForm.propTypes = {
  googleReCaptchaProps: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
